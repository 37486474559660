import debounce from "lodash/debounce";
import { useCallback, useEffect, useState } from "react";

export default function ScrollProgressBar() {
  const [scrollProgress, setScrollProgress] = useState(0);

  // Define the scroll handler
  const handleScroll = useCallback(
    debounce(() => {
      const scrollTop = window.scrollY; // Current scroll position
      const scrollHeight = document.documentElement.scrollHeight; // Total height of the page
      const clientHeight = window.innerHeight; // Height of the viewport
      const totalScrollableHeight = scrollHeight - clientHeight;

      // Calculate the scroll progress as a percentage
      const progress = (scrollTop / totalScrollableHeight) * 100;
      setScrollProgress(progress);
    }, 5), // Debounce delay (in milliseconds)
    []
  );

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll); // Cleanup on unmount
      handleScroll.cancel(); // Cancel any pending debounced calls
    };
  }, [handleScroll]);

  return (
    // <div style={styles.container}>
    <div className="sticky top-0 left-0 w-full h-[5px] bg-white z-[9999]">
      <div
        className="h-full bg-primary transition-[width] duration-100 ease-out"
        style={{
          width: `${scrollProgress}%`,
        }}
      />
    </div>
  );
}
